<style lang="scss">
  @import './VueStoryTime.scss';
</style>

<template>
  <div class="time">
    <div
        class="time__item"
        v-for="(elm, index) in items"
        :key="index"
    >
        <div
            class="time__fill"
            :style="
                index === current
                ? `width: ${percent}%`
                    : current > index
                    ? `width:100%`
                    : `width:0%`
            "></div>
    </div>
</div>
</template>

<script>

export default {
  name: 'VueStoryTime',
  props: {
    percent: {
      type: Number,
      required: true,
    },
    current: {
        type: Number,
        required: true,
    },
    items : {
        type: Number,
        required: true,
    }
  }
}
</script>