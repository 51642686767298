<style lang="scss">
  @import './VueStoryInnerContent.scss';
</style>

<template>
    <div class="inner__content">
      <h3 v-if="text !== null">{{text}}</h3>
      <div>
        <a :href="url" @click="$emit('click')" :target="target" class="btn" :class="btnClass">
            <i class="mr-2" :class="iconClass" v-if="iconClass !== null"></i>
            {{label}}
        </a>
      </div>
    </div>
</template>

<script>
export default {
  name: 'VueStoryInnerContent',
  props: {
    url: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      required: false,
    },
    label : {
      type: String,
      required: true,
    },
    text : {
      type: String,
      required: false,
      default: null
    },
    btnClass : {
      type: String,
      required: false,
    },
    iconClass : {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>