<style lang="scss">
  @import './StoryLister.scss';
</style>

<template>
  <div v-if="stories.length > 0" class="story-lister">
    <div class="story-item" v-for="([index, story]) in contentStories" :key="index">
      <div v-if="story.type === 'content'" @click="$emit('storySelect', index)">
        <div class="story-image">
          <img :src="story.picture" :alt="story.username"/>
        </div>
        <label v-if="story.label">{{story.label}}</label>
        <div v-if="story.username">{{story.username}}</div>
      </div>
    </div>
  </div>
  <div v-else class="story-lister">
    <div class="story-item" v-for="n in 10" :key="n"></div>
  </div>
</template>

<script>

export default {
  name: 'StoryLister',
  props: {
    stories: []
  },
  computed: {
    contentStories() {
      return this.stories.reduce(
              (acc, story, index) => {
                if (story.type === 'content')
                  acc.push([index, story])

                return acc;
              }, 
              []
            );
    }
  }
}
</script>