<style lang="scss">
  @import './VueStoryAdv.scss';
</style>

<template>
    <div class="story__source">
        <div :id="uniqueId" @click="$emit('click')"></div>
    </div>
</template>

<script>
export default {
  name: 'VueStoryAdv',
  props: {
    siteId: {
      type: Number,
      required: true,
    },
    pageId : {
        type: Number,
        required: true,
    },
    formatId : {
        type: Number,
        required: true,
    },
    target: {
        type: String,
        required: false,
        default: ""
    }
  },
  computed: {
    uniqueId() {
        return `adv_${parseInt(Math.random() * 1000000)}`;
    }
  },
  mounted(){
    const { sas = { cmd: [] } } = window;
    sas.cmd.push(() => {
        const { siteId, pageId, formatId, target, uniqueId } = this;
        sas.call('std',{
            siteId,
            pageId,
            formatId,
            target,
            tagId: uniqueId
        });
    });
  }
}
</script>