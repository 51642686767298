<style lang="scss">
  @import './StoriesContainer.scss';
</style>

<template>
  <div class="stories-container">
    <StoryLister :stories="items" @storySelect="onStorySelect" />
    <div v-if="show">
      <VueStories 
        :storiesProp="uniqueItems" 
        :currentIndex="currentIndex"
        @closeStories="onCloseStories" 
        @allStoriesEnd="onCloseStories" />
    </div>
  </div>
</template>

<script>
import VueStories from "./components/VueStories/VueStories.vue";
import StoryLister from './components/StoryLister/StoryLister.vue'

export default {
  name: 'StoriesContainer',
  components: {
    VueStories,
    StoryLister
  },
  data: () => ({
    show: false,
    currentIndex: -1,
    items: [],
    uniqueItems: []
  }),
  computed: {
    contentStories(){
      return this.items.filter(n => n.type === 'content');
    },
    generateUniqueKey() {
      return Math.floor((Math.random() * 999999) + 1);
    }
  },
  methods: {
    toggleShow(newValue = null) {
      if (newValue == null)
        newValue = !this.show;
      
      this.show = newValue;
    },
    onCloseStories() {
      this.toggleShow(false);
    },
    setIndex(newIndex) {
      this.currentIndex = parseInt(newIndex, 10);
    },
    setItems(items = []) {
      this.items = items;
    },
    onStorySelect(index = 0){
      this.uniqueItems = JSON.parse(JSON.stringify(this.items));
      this.setIndex(index);
      this.toggleShow(true);
    }
  }
}
</script>