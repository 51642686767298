<style lang="scss">
  @import './VueStories.scss';
</style>

<template>
  <div class="stories-wrapper">
    <div @click="closeStories" class="close"><i class="icon-close"></i></div>
    <div class="stories">
      <div
          v-for="(story, index) in stories"
          :key="index"
          class="story"
          :style="
            index === indexSelected
              ? `transform: translate(0px)`
              : `transform: translate(${calculateTransform(index)}px) scale(0.75); cursor:pointer;`
          "
          @click="index !== indexSelected ? selectSlide(index) : ''"
      >
        <VueStoryAdv v-if="story.type === 'adv'" v-bind="getAdv(story, index)" @click="closeStories"/>
        <div class="story__source" v-if="story.type === 'content'">
          <video ref="videoplayer" v-if="getSrc(story, index).type === 'video'"
                 :src="getSrc(story, index).url" autoplay></video>
          <img
              v-else
              :src="getSrc(story, index).url"
              alt=""
          />
          <div class="story__header" v-if="index === indexSelected">
            <VueStoryTime :items="story.items.length" :percent="percent" :current="key" />
            <div class="story__top">
              <div class="user">
                <div class="user__image">
                  <img :src="story.picture" :alt="story.username"/>
                </div>
                <div class="user__name">
                  {{ story.username }} 
                </div>
              </div>
              <div class="story__playpause" @click="isPaused ? playStory($event) : pauseStory($event)">
                <i class="icon-play" v-if="isPaused"></i>
                <i class="icon-pause" v-else></i>
              </div>
            </div>
          </div>
          <div class="story__body">
            <div class="user" v-if="index !== indexSelected">
              <div class="user__image" :class="getNotViewedIndex(story) === -1 ? `not__current` : ''">
                <img :src="story.picture" alt=""/>
              </div>
              <div class="user__name">
                {{ story.username }} 
              </div>
            </div>
            <VueStoryInnerContent @click="closeStories" v-if="index === indexSelected && getContent(story, index)" v-bind="getContent(story, index)"/>
          </div>
        </div>
        <div v-if="index === indexSelected" ref="arrowLeft" class="story__icon story__icon--prev" @click="prev(index)">
          <i class="icon-chevron-left"></i>
        </div>
        <div v-if="index === indexSelected" ref="arrowRight" class="story__icon story__icon--next" @click="next(index)">
          <i class="icon-chevron-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import VueStoryTime from "../VueStoryTime/VueStoryTime.vue";
import VueStoryInnerContent from '../VueStoryInnerContent/VueStoryInnerContent.vue';
import VueStoryAdv from '../VueStoryAdv/VueStoryAdv.vue';

let touchStartX, touchEndX, touchStartY, touchEndY = 0;
let directional_threshold = 10;

export default {
  name: 'VueStories',
  components: {
    VueStoryTime,
    VueStoryInnerContent,
    VueStoryAdv
  },
  props: {
    storiesProp: {
      type: Array,
      required: true,
    },
    currentIndex: {
      type: Number,
      default: 0,
    }
  },
  data: () => ({
    indexSelected: 0,
    difference: 0,
    key: 0,
    percent: 0,
    timer: 0,
    progress: 0,
    interval: 0,
    isPaused: false,
    newDur: 0,
    pausePer: 0,
    duration: 3000,
  }),
  computed: {
    stories() {
      return this.storiesProp ?? [];
    },
    isAllStoriesEnd() {
      return this.indexSelected >= this.stories.length - 1 && this.isCurrentAllImagesEnd;
    },
    isCurrentAllImagesEnd() {
      return this.key >= this.stories[this.indexSelected].items.length - 1;
    }
  },
  methods: {
    getCurrentIndex(story, index){
      return (index === this.indexSelected)
        ? this.key
        : this.getLastViewedIndex(story)
    },
    getSrc(story, index) {
      const currentIndex = this.getCurrentIndex(story, index); 

      const { url, type } = story.items[currentIndex];

      return { url, type };
    },
    getAdv(story, index){
      const currentIndex = this.getCurrentIndex(story, index); 

      const { siteId = 0, pageId = 0, formatId = 0, target = 0 } = story.items[currentIndex];

      return { siteId, pageId, formatId, target };
    },
    getContent(story, index) {
      const currentIndex = this.getCurrentIndex(story, index); 

      const { innerContent = false } = story.items[currentIndex];

      return innerContent;
    },
    resetToDefault(){
      clearInterval(this.progress);
      clearInterval(this.interval);
      this.key = 0;
    },
    getNotViewedIndex(story) {
      return story.items.findIndex(item => !item.viewed);
    },
    getLastViewedIndex(story) {
      const keyIndex = this.getNotViewedIndex(story);
      const index = story.items.length - 1;
      return keyIndex === -1 ? index : keyIndex;
    },
    selectSlide(index) {
      this.isPaused = false;
      this.difference += this.indexSelected - index;
      this.stopVideo();
      this.indexSelected = index;
      this.key = this.getLastViewedIndex(this.stories[this.indexSelected]);
      this.reset()
    },
    onAllStoriesEnd() {
      this.resetToDefault();
      this.$emit('allStoriesEnd');
    },
    onCurrentAllImagesEnd(index) {
      this.difference += index - (index + 1);
      this.stopVideo();
      this.stories[index].items[this.key].viewed = true;
      this.indexSelected++;
      this.key = this.getLastViewedIndex(this.stories[this.indexSelected]);
      this.$emit('сurrentAllImagesEnd', index);
    },
    onCurrentImageEnd(index) {
      this.stories[index].items[this.key].viewed = true;
      this.$emit('сurrentImageEnd', this.key);
      this.key++;
    },
    next(index) {
      this.isPaused = false;
      if (this.isAllStoriesEnd) {
        this.onAllStoriesEnd();
      } else if (this.isCurrentAllImagesEnd) {
        setTimeout(() => {
          this.onCurrentAllImagesEnd(index);
        })
      } else {
        this.stories[this.indexSelected].items[this.key].viewed = true;
        this.key++
      }
      this.reset()
    },
    prev(index) {
      this.isPaused = false;
      if (this.indexSelected <= 0 && this.key <= 0) {
        this.key = 0
      } else if (this.key <= 0) {
        // Without delay
        setTimeout(() => {
          this.difference += index - (index - 1);
          this.indexSelected--;
          this.key = this.getLastViewedIndex(this.stories[this.indexSelected]);
        })
      } else {
        this.key--;
        this.stories[this.indexSelected].items[this.key].viewed = false;
      }
      this.reset()
    },
    autoPlay() {
      if (this.isAllStoriesEnd) {
        this.onAllStoriesEnd();
      } else if (this.isCurrentAllImagesEnd) {
        this.onCurrentAllImagesEnd(this.indexSelected);
      } else {
        this.onCurrentImageEnd(this.indexSelected);
      }
      this.reset();

    },
    play() {
      this.timer = new Date().getTime();
      
      this.progress = setInterval(() => {
        // forward
        let time = new Date().getTime()
        if (this.newDur > 0) {
          this.percent =
              this.pausePer +
              Math.floor((100 * (time - this.timer)) / this.duration);
        } else {
          this.percent = Math.floor((100 * (time - this.timer)) / this.duration);
        }
      }, this.duration / 100)
      
      if (this.newDur > 0) {
        this.interval = setInterval(this.autoPlay, this.newDur)
      } else {
        this.interval = setInterval(this.autoPlay, this.duration)
      }
    },
    reset() {
      this.percent = 0;
      this.duration = this.stories[this.indexSelected].items[this.key].duration;
      clearInterval(this.interval);
      clearInterval(this.progress);
      this.newDur = 0;
      this.play();
    },
    pauseStory(event) {
      if (event) {
        this.toggleVideo('pause');
      }
      this.isPaused = true;
      this.pausePer = this.percent;
      clearInterval(this.progress);
      clearInterval(this.interval);
      this.newDur = this.duration - (this.pausePer * this.duration) / 100;
    },
    playStory(event) {
      if (event) {
        this.toggleVideo('play', event);
      }
      this.isPaused = false;
      this.play();
    },
    toggleVideo(type) {
      if (this.$refs.videoplayer && this.$refs.videoplayer.length > 0){
        this.$refs.videoplayer[0][type]();
      }
    },
    stopVideo() {
      if (this.$refs.videoplayer && this.$refs.videoplayer.length > 0){
        this.$refs.videoplayer[0].pause();
        this.$refs.videoplayer[0].currentTime = 0;
      }
    },
    calculateTransform(index) {
      if (this.indexSelected - index === -1 || this.indexSelected - index === 1) {
        return 470 * (index + this.difference);
      }
      if (index > this.indexSelected) {
        return (470 + ((310 + 35) * (index + this.difference - 1)));
      } else {
        return Math.abs(470 - ((310 + 35) * (index + this.difference + 1))) * -1;
      }
    },
    closeStories(){
      this.resetToDefault();
      this.$emit('closeStories');
    },
    onKeyPress(e){
      const { keyCode = 0 } = e;
      if (keyCode === 37){ // arrowLeft
        if (this.$refs.arrowLeft.length > 0)
          this.$refs.arrowLeft[0].click();
      }
      if (keyCode === 39){ // arrowRight
        if (this.$refs.arrowRight.length > 0)
          this.$refs.arrowRight[0].click();
      }
      if (keyCode === 27){ // esc
        this.closeStories();
      }
    },
    handleGesture(/*event*/){
      if (touchEndX < touchStartX && (Math.max(touchStartY, touchEndY) - Math.min(touchStartY, touchEndY)) < directional_threshold) {
        if (this.$refs.arrowRight.length > 0)
          this.$refs.arrowRight[0].click();
        //onSwipeLeft.forEach(callback => callback(event));
      }

      if (touchEndX > touchStartX && (Math.max(touchStartY, touchEndY) - Math.min(touchStartY, touchEndY)) < directional_threshold) {
        if (this.$refs.arrowLeft.length > 0)
          this.$refs.arrowLeft[0].click();
        //onSwipeRight.forEach(callback => callback(event));
      }

      if (touchEndY < touchStartY && (Math.max(touchStartX, touchEndX) - Math.min(touchStartX, touchEndX)) < directional_threshold) {
        //onSwipeUp.forEach(callback => callback(event));
      }

      if (touchEndY > touchStartY && (Math.max(touchStartX, touchEndX) - Math.min(touchStartX, touchEndX)) < directional_threshold) {
        //onSwipeDown.forEach(callback => callback(event));
      }

      if (touchEndY === touchStartY) {
        //onTap.forEach(callback => callback(event));
      }
    },
    onTouchStart(event) {
      touchStartX = event.changedTouches[0].screenX;
      touchStartY = event.changedTouches[0].screenY;
    },
    onTouchEnd(event) {
      touchEndX = event.changedTouches[0].screenX;
      touchEndY = event.changedTouches[0].screenY;
      this.handleGesture(event);
    }
  },
  mounted() {
    this.play();
    this.selectSlide(this.currentIndex);
    document.addEventListener('keyup', this.onKeyPress);
    document.addEventListener('touchstart', this.onTouchStart);
    document.addEventListener('touchend', this.onTouchEnd);
  },
  destroyed() {
    document.removeEventListener('keyup', this.onKeyPress);
    document.removeEventListener('touchstart', this.onTouchStart);
    document.removeEventListener('touchend', this.onTouchEnd);
    this.indexSelected = 0;
    this.difference = 0;
    this.key = 0;
    this.percent = 0;
    this.timer = 0;
    clearInterval(this.progress);
    clearInterval(this.interval);
    this.isPaused = true;
    this.newDur = 0;
    this.pausePer = 0;
    this.duration = 3000;
  }
}
</script>